.loginPageContainer {
    background: url('Telko.jpg') no-repeat;
    background-size: 100%;
    height: 100vh;
}

.loginPage {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 30em;
    height: 18em;
    margin-top: -9em; /*set to a negative number 1/2 of your height*/
    margin-left: -15em; /*set to a negative number 1/2 of your width*/
    border: 1px solid #ccc;
}

