

/* width */
::-webkit-scrollbar {
    width: .5em;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 .5em grey;
    border-radius: 1em;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #A4A4A4;
    border-radius: 1em;
}

.deletedItem {
    position: relative;
    /*background-color: darkgray ;*/
    padding: 4em;
    overflow: hidden;
}

    .deletedItem:before, .deletedItem:after {
        /*position: absolute;
        content: '';
        background: black;
        display: block;
        width: 100%;
        height: 2px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;*/
        text-decoration: line-through;
    }

.deletedItem:after {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.searchListHeight {
    height: 90%;
    padding: 0;
    overflow-y:hidden;
}

.ExtraArea {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    height: 100%;
}

/*.hardwareCard {
    height: 80%;
}*/

.CommentCardHeader{
    height: 2vh;
}

.CommentCardContent {
    max-height: 35vh;
    overflow: auto;
}

.InputLabel {
    color: rgba(0, 0, 0, 0.87);
    padding: 0;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1;
    display: inline-block;
    margin-bottom: 0.5rem;
    box-sizing: border-box;
    cursor: default;
    text-align: left;
}

.InputLabel:disabled {
    color: rgba(0, 0, 0, 0.87);
    padding: 0;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1;
    display: inline-block;
    margin-bottom: 0.5rem;
    box-sizing: border-box;
}

.DisabledInputValue {
    opacity: 1;
    /*color: rgba(0, 0, 0, 0.87);*/
    color: red;
}

.DistributorFieldLabel {
    -webkit-transform: translate(14px, -6px) scale(0.75);
            transform: translate(14px, -6px) scale(0.75);
    z-index: 10;
    pointer-events: none;
    -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,-webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,-webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,-webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    top: 0;
    left: 0;
    position: absolute;
    color: Rgba(0, 0, 0, 0.87);
    padding: 0;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1;
    display: inline-block;
    margin-bottom: 0.5rem;
    box-sizing: border-box;
    cursor: default;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    background-color: white;
}

.DistributorField {
    z-index: 0;
    color: #0071BC;
    height: 2.5em;
    font: inherit;
    width: 100%;
    border: 0;
    margin: 0;
    display: block;
    box-sizing: content-box;
    background: none;
    -webkit-tap-highlight-color: transparent;
    overflow: visible;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    text-align: start;
    overflow: visible;
    box-sizing: border-box;
    border-radius: .2em;
    border: solid 1px;
    padding: 0em .7em;
    border-color: Rgba(0, 0, 0, 0.25);
}

    .DistributorField:focus, .DistributorField:hover {
        background-color: transparent;
        outline: none;
        border-radius: .2em;
        border: solid 1px;
        color: #0071BC;
        border-color: Rgba(0, 0, 0, 0.87);
    }

    .DistributorField:disabled {
        color: Rgba(0, 0, 0, 0.87);
        padding: 0em .7em;
        font: inherit;
        width: 100%;
        border: 0;
        margin: 0;
        display: block;
        min-width: 0;
        box-sizing: content-box;
        background: none;
        -webkit-tap-highlight-color: transparent;
        overflow: visible;
        box-sizing: border-box;
        border-radius: .2em;
        border: solid 1px;
        border-color: Rgba(0, 0, 0, 0.25);
    }

.DistributorFieldDiv {
    position: relative;
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    display: -webkit-inline-flex;
    display: inline-flex;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.1875em;
    -webkit-align-items: center;
            align-items: center;
    box-sizing: border-box;
    font-weight: 400;
    text-align: left;
}

.DistributorFieldContainer {
    width: 100%;
    /*width: 14em;*/
    /*margin: 5px;*/
    height: 2.4em;
    border: 0;
    display: -webkit-inline-flex;
    display: inline-flex;
    padding: 0;
    position: relative;
    -webkit-flex-direction: column;
            flex-direction: column;
    vertical-align: top;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}
.softwareDistributorFieldContainer {
    width: 100%;
    /*width: 21em;
    margin: 5px;*/
    height: 2.4em;
    border: 0;
    display: -webkit-inline-flex;
    display: inline-flex;
    padding: 0;
    position: relative;
    -webkit-flex-direction: column;
            flex-direction: column;
    vertical-align: top;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}

.RmaDistributorFieldContainer {
    width: 100%;
    /*width: 11em;
    margin: 5px;*/
    height: 2.4em;
    border: 0;
    display: -webkit-inline-flex;
    display: inline-flex;
    padding: 0;
    position: relative;
    -webkit-flex-direction: column;
            flex-direction: column;
    vertical-align: top;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}

.deletedItem {
    background-color: #ffefef8f;
}
.informationListClass {
    max-height: 40vh;
    overflow: auto;
}

.topNavBar {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    /*height: 2em;*/
}

.topNavBarHeading {
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.NavBarButton {
    background: url(/static/media/telko.3fb10031.svg) no-repeat;
    margin-left: 1em;
    width: 1em;
}

.loginPageContainer {
    background: url(/static/media/Telko.2ee91728.jpg) no-repeat;
    background-size: 100%;
    height: 100vh;
}

.loginPage {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 30em;
    height: 18em;
    margin-top: -9em; /*set to a negative number 1/2 of your height*/
    margin-left: -15em; /*set to a negative number 1/2 of your width*/
    border: 1px solid #ccc;
}


.user-role-container {
    width:100%;
}

@media all and (min-width: 480px) {
  .userdetails {
    padding: 60px 0;
  }

  .userdetails form {
    margin: 0 auto;
  }
}

.user-role-container {
    width:100%;
}

.LoaderButton .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}

body, html {
    height: 100%;
    padding:0;
}
.App {
    /*margin-top: 15px;*/
}

.App .navbar-brand {
    font-weight: bold;
}

.ts-fullheight {
    height:100vh;
    padding:0;
}

.ts-sidebar {
    background-color: none;
    padding: 1em;
    background-clip: content-box; /* <---- */
    box-shadow: inset 0 0 0 .5em #F6F6F6;
    float:left;
}

.main-section {
    padding: 0 1.5em ;
    background-clip: content-box; /* <---- */
    box-shadow: inset 0 0 0 .5em #F6F6F6;
    float:left;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

