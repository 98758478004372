.topNavBar {
    flex-grow: 1;
    /*height: 2em;*/
}

.topNavBarHeading {
    flex-grow: 1;
}

.NavBarButton {
    background: url('telko.svg') no-repeat;
    margin-left: 1em;
    width: 1em;
}
