body, html {
    height: 100%;
    padding:0;
}
.App {
    /*margin-top: 15px;*/
}

.App .navbar-brand {
    font-weight: bold;
}

.ts-fullheight {
    height:100vh;
    padding:0;
}

.ts-sidebar {
    background-color: none;
    padding: 1em;
    background-clip: content-box; /* <---- */
    box-shadow: inset 0 0 0 .5em #F6F6F6;
    float:left;
}

.main-section {
    padding: 0 1.5em ;
    background-clip: content-box; /* <---- */
    box-shadow: inset 0 0 0 .5em #F6F6F6;
    float:left;
}
