
/*.hardwareCard {
    height: 80%;
}*/

.CommentCardHeader{
    height: 2vh;
}

.CommentCardContent {
    max-height: 35vh;
    overflow: auto;
}

.InputLabel {
    color: rgba(0, 0, 0, 0.87);
    padding: 0;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1;
    display: inline-block;
    margin-bottom: 0.5rem;
    box-sizing: border-box;
    cursor: default;
    text-align: left;
}

.InputLabel:disabled {
    color: rgba(0, 0, 0, 0.87);
    padding: 0;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1;
    display: inline-block;
    margin-bottom: 0.5rem;
    box-sizing: border-box;
}

.DisabledInputValue {
    opacity: 1;
    /*color: rgba(0, 0, 0, 0.87);*/
    color: red;
}

.DistributorFieldLabel {
    transform: translate(14px, -6px) scale(0.75);
    z-index: 10;
    pointer-events: none;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    transform-origin: top left;
    top: 0;
    left: 0;
    position: absolute;
    color: Rgba(0, 0, 0, 0.87);
    padding: 0;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1;
    display: inline-block;
    margin-bottom: 0.5rem;
    box-sizing: border-box;
    cursor: default;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    background-color: white;
}

.DistributorField {
    z-index: 0;
    color: #0071BC;
    height: 2.5em;
    font: inherit;
    width: 100%;
    border: 0;
    margin: 0;
    display: block;
    box-sizing: content-box;
    background: none;
    -webkit-tap-highlight-color: transparent;
    overflow: visible;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    text-align: start;
    overflow: visible;
    box-sizing: border-box;
    border-radius: .2em;
    border: solid 1px;
    padding: 0em .7em;
    border-color: Rgba(0, 0, 0, 0.25);
}

    .DistributorField:focus, .DistributorField:hover {
        background-color: transparent;
        outline: none;
        border-radius: .2em;
        border: solid 1px;
        color: #0071BC;
        border-color: Rgba(0, 0, 0, 0.87);
    }

    .DistributorField:disabled {
        color: Rgba(0, 0, 0, 0.87);
        padding: 0em .7em;
        font: inherit;
        width: 100%;
        border: 0;
        margin: 0;
        display: block;
        min-width: 0;
        box-sizing: content-box;
        background: none;
        -webkit-tap-highlight-color: transparent;
        overflow: visible;
        box-sizing: border-box;
        border-radius: .2em;
        border: solid 1px;
        border-color: Rgba(0, 0, 0, 0.25);
    }

.DistributorFieldDiv {
    position: relative;
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    display: inline-flex;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.1875em;
    align-items: center;
    box-sizing: border-box;
    font-weight: 400;
    text-align: left;
}

.DistributorFieldContainer {
    width: 100%;
    /*width: 14em;*/
    /*margin: 5px;*/
    height: 2.4em;
    border: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    flex-direction: column;
    vertical-align: top;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}
.softwareDistributorFieldContainer {
    width: 100%;
    /*width: 21em;
    margin: 5px;*/
    height: 2.4em;
    border: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    flex-direction: column;
    vertical-align: top;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}

.RmaDistributorFieldContainer {
    width: 100%;
    /*width: 11em;
    margin: 5px;*/
    height: 2.4em;
    border: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    flex-direction: column;
    vertical-align: top;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}

.deletedItem {
    background-color: #ffefef8f;
}