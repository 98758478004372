

/* width */
::-webkit-scrollbar {
    width: .5em;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 .5em grey;
    border-radius: 1em;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #A4A4A4;
    border-radius: 1em;
}

.deletedItem {
    position: relative;
    /*background-color: darkgray ;*/
    padding: 4em;
    overflow: hidden;
}

    .deletedItem:before, .deletedItem:after {
        /*position: absolute;
        content: '';
        background: black;
        display: block;
        width: 100%;
        height: 2px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;*/
        text-decoration: line-through;
    }

.deletedItem:after {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.searchListHeight {
    height: 90%;
    padding: 0;
    overflow-y:hidden;
}

.ExtraArea {
    display: flex;
    flex-flow: column;
    height: 100%;
}